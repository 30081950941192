import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '../../grid';
import { HeaderLarge, Small, Medium } from '../../text';
import { generateTimeElement } from '../../../utils/generateDateString';

const H1 = styled(HeaderLarge).attrs(props => ({ as: 'h1', weight: 'medium' }))`
  padding-right: ${props => props.theme.spacing(9)};
  transition: color 0.2s ease;
  font-size: 24px;
  line-height: 32px;

  @media ${props => props.theme.breakpoints.md} {
    padding-right: ${props => props.theme.spacing(4)};
    margin-bottom: ${props => props.theme.spacing(2)};
  }

  @media ${props => props.theme.breakpoints.sm} {
    padding-right: 0;
  }
`;

const H2 = styled(Medium)`
  margin-top: ${props => props.theme.spacing(1)};
`;

const Article = styled(Row).attrs(props => ({ as: 'article' }))`
  border-top: 1px solid ${props => props.theme.color.nbPrimary};
  padding: ${props => props.theme.spacing(5)} 0;

  &:hover ${H1} {
    color: ${props => props.theme.color.nbPrimary};
  }

  @media ${props => props.theme.breakpoints.sm} {
    padding: ${props => props.theme.spacing(4)} 0;
  }
`;

export const BlogPost = ({ title, createdAt, updatedAt, subtitle }) => (
  <Article>
    <Col lg={5} md={5} sm={9} xs={12}>
      <H1>{title}</H1>
    </Col>
    <Col lg={6} md={7} sm={9} xs={12}>
      <Small colored="greyText">{generateTimeElement(createdAt, updatedAt)}</Small>
      <H2>{subtitle}</H2>
    </Col>
  </Article>
);
